@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.live-blog.pkg.live-blog--standalone.live-blog--in-rail {
  margin-top: 30px;

  @include h.breakpoint-l {
    margin-top: initial;
  }
}

.live-blog-pkg {
  // Used by the mobile app team as a web render
  &--embedded {
    border-top: solid var(--space-4) var(--live-blog-pkg-border-top-color);
    padding-top: var(--space-16);
    margin-bottom: 0 !important; // required to override pkg specificity

    &.live-blog-pkg--breaking {
      border-top-color: var(--live-blog-pkg-border-top-color-breaking);
    }

    @include h.breakpoint-m {
      display: flex;
      justify-content: space-between;

      .live-blog-pkg__column {
        flex-basis: 30%;

        &:not(:last-child) {
          margin-right: var(--space-20);
        }
      }

      .live-blog-pkg__headline {
        margin-right: var(--space-20);
        margin-bottom: 0;
        margin-top: 0;
      }

      .live-blog-pkg__list {
        flex-grow: 2;

        &--wrapped {
          flex-flow: row wrap;
          display: flex;

          > * {
            // Update children into a two column grid
            flex-basis: 50%;

            // Update order since column wrap leads to weird side effects
            &:nth-child(1) {
              order: 1;
            }

            &:nth-child(2) {
              order: 3;
            }

            &:nth-child(3) {
              order: 2;
            }

            &:nth-child(4) {
              order: 4;
            }

            &:nth-child(2)::after {
              display: none;
            }
          }
        }
      }
    }

    // Write custom IE & MD Media query since nested queries
    // aren't supported on ie11
    @media only screen and (min-width: h.$grid-m-width) and (-ms-high-contrast: none),
      (-ms-high-contrast: active) {
      .live-blog-pkg__list--wrapped > * {
        // Rows don't wrap without removing padding from flex basis
        flex-basis: calc(50% - #{t.$total-padding});
      }
    }
  }

  &__headline {
    font-family: var(--live-blog-pkg-headline-font-family);
    font-weight: var(--live-blog-pkg-headline-font-weight);
    font-style: var(--live-blog-pkg-headline-font-style);
    line-height: var(--live-blog-pkg-headline-line-height);
    letter-spacing: var(--live-blog-pkg-headline-letter-spacing);
    margin: 0;

    &:hover {
      opacity: var(--default-hover-opacity);
    }

    &:active {
      opacity: var(--default-active-opacity);
    }

    &__text {
      margin: 0;
      color: var(--live-blog-pkg-headline-color);
      font-size: var(--text-24);
      line-height: var(--leading-100);
      font-family: var(--live-blog-pkg-headline-font-family);
    }

    &__label {
      font-family: var(--live-blog-pkg-label-font-family);
      font-weight: var(--live-blog-pkg-label-font-weight);
      font-size: var(--live-blog-pkg-label-font-size);
      font-style: normal;
      color: var(--live-blog-pkg-label-color);
      line-height: var(--live-blog-pkg-label-line-height);

      &--breaking {
        color: var(--live-blog-pkg-label-color-breaking);
      }

      &--headline-text-hidden {
        font-family: var(--live-blog-pkg-label-font-family-headline-text-hidden);
        font-size: var(--live-blog-pkg-label-font-size-headline-text-hidden);
        line-height: var(--live-blog-pkg-label-line-height-headline-text-hidden);
      }
    }

    &.live-blog-pkg__headline--rail {
      @include h.breakpoint-x {
        font-size: 20px;

        .live-blog-pkg__headline__label {
          font-size: 18px;
        }

        .live-blog-pkg__headline__text {
          font-size: 20px;
        }
      }
    }
  }

  &__dek {
    color: inherit;
    font-size: var(--text-14);
    font-family: var(--publico-txt);
    line-height: var(--leading-150);
    margin: var(--space-12) 0 var(--space-12);
  }

  &__list {
    margin-left: var(--space-4);

    &.live-blog-pkg__list--rail {
      li:nth-of-type(5) {
        @include h.breakpoint-x {
          display: none;
        }
      }

      li:nth-of-type(4) {
        @include h.breakpoint-x {
          &::after {
            display: none;
          }
        }
      }
    }
  }

  // font size override headline when used in storyline
  &--storyline {
    .live-blog-pkg__headline__text {
      font-size: h.rem(28px);
      font-weight: 600;

      @include h.breakpoint-m {
        font-size: 24px;
      }
    }

    .live-blog-pkg__dek {
      line-height: var(--leading-150);
      margin: 8px 0 3px;
    }
  }

  .live-blog--Storyline &,
  .live-blog--MultiStoryline3 &,
  .live-blog--MultiStoryline3Preset & {
    .live-blog-pkg__list {
      margin-top: 8px;
    }
  }

  .live-blog--MultiStoryline3 &,
  .live-blog--MultiStoryline3Preset & {
    .live-blog-pkg__list {
      &--storylineChatStyle {
        margin-top: 0;
      }
    }
  }
}

.onlyBlog {
  .live-blog-pkg {
    .live-blog-pkg__headline {
      display: none;
    }

    .live-blog-pkg__dek {
      display: none;
    }
  }
}

.live-blog-pkg__headline--chatStyle:has(.live-blog-pkg__headline__label--headline-text-hidden) {
  font-size: var(--live-blog-pkg-headline-font-size-hidden);
}

.live-blog-pkg__liveBadge.isInline {
  transform: translateY(-2px);
  display: inline-flex;
  vertical-align: middle;

  .badge-label {
    margin-bottom: 0;
    bottom: 0;
  }
}
