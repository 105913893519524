@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.filterContainer {
  position: relative;
  line-height: var(--filter--text--line-height);

  &:has(.closed) {
    max-height: 44px;
  }

  .closedTitle {
    opacity: 1;
    color: var(--filter--name--color);
    font-family: var(--filter--name--font-family);
    font-size: var(--filter--name--font-size);
    font-weight: var(--filter--name--font-weight);
    cursor: pointer;
    letter-spacing: var(--filter--name--letter-spacing);
    height: 44px;
    text-align: left;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    border: 0;
    padding: 0 20px;
    position: relative;

    .arrow {
      color: var(--primary-color);
      font-size: h.rem(20px);
    }

    &.visible {
      opacity: 1;
    }

    &.hidden {
      opacity: 0;
      height: 0;
      margin: 0;
    }

    &:focus-visible {
      outline: var(--filter-outline);
      outline-offset: 1px;
    }

    &:hover {
      @include h.breakpoint-l {
        background-color: var(--filter--button--hover--background-color);
        color: var(--filter--button--hover--font-color);
        border-color: transparent;

        .arrow {
          color: var(--filter--button--hover--font-color);
        }
      }
    }

    @include h.breakpoint-l {
      gap: 0 4px;
      margin: 0;
      overflow: hidden;
      padding: var(--filter--name--padding);
      text-overflow: ellipsis;
      white-space: nowrap;
      align-items: center;
      background: var(--waffle-header--cta-button--background);
      border-radius: var(--waffle-header--cta-button--border-radius);
      border: 1px solid var(--waffle-header--cta-button--color);
      color: var(--waffle-header--cta-name--color);
      column-gap: 4px;
      display: flex;
      position: relative;
      font-family: var(--waffle-header--cta-button--font-family);
      font-size: var(--waffle-header--cta-button--font-size);
      font-weight: var(--waffle-header--cta-button--font-weight);
      text-transform: var(--waffle-header--cta-button--text-transform);
      height: h.rem(44px);
      justify-content: center;
      width: var(--waffle-header--cta-button--width);
      max-width: 300px;
    }

    @include h.breakpoint-x {
      max-width: unset;
    }
  }

  .filterWrapper {
    background-color: transparent;
    cursor: pointer;
    margin: h.rem(0 8px 16px);

    @include h.breakpoint-l {
      margin: 0;
    }

    &.closed {
      pointer-events: none;
      overflow: hidden;
      opacity: 0;
    }

    &.open {
      transform: translateY(0);
      overflow: visible;
      opacity: 1;

      * > {
        pointer-events: none; // two direct children, both can eat clicks in open state
      }

      @include h.breakpoint-x {
        width: var(--filter--button--max-width);
      }
    }

    .accordion {
      align-items: flex-start;
      background-color: var(--white);
      border: 0;
      display: flex;
      flex-direction: column;
      text-align: left;
      z-index: 999;

      legend {
        height: 0;
        width: 0;
        opacity: 0;
        overflow: hidden;
      }

      @include h.breakpoint-l {
        z-index: 999;
        display: block;
        width: var(--filter--name--width);
        padding: 0;
        max-width: 300px;
        margin: 0;
      }

      @include h.breakpoint-x {
        max-width: var(--filter--button--max-width);
      }

      .openTitle {
        font-family: var(--filter--name--font-family);
        font-size: var(--filter--name--font-size);
        font-weight: var(--filter--name--font-weight);
        cursor: pointer;
        letter-spacing: var(--filter--name--letter-spacing);
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 0;
        padding: 5px 0 16px;
        line-height: var(--filter--text--line-height);
        background-color: transparent;
        color: var(--filter--name--color);

        &.closed {
          display: none;
        }

        @include h.breakpoint-l {
          padding: 0;
          text-align: center;
          justify-content: center;
          height: 44px;
          max-width: var(--filter--button--max-width);
          color: var(--filter--button--hover--font-color);
          background-color: var(--filter--button--hover--background-color);
          border-radius: var(--filter--button--hover--top-border-radius);
          text-transform: var(--filter--button--text-transform);
        }

        .multiple & {
          // Separate rules to offer control of width without cssnano 'optimizing' and breaking style
          @include h.breakpoint-m-max {
            justify-content: space-between;
          }
        }

        .arrow {
          transform: rotate(180deg);
          font-size: h.rem(20px);
          margin-left: 4px;

          @include h.breakpoint-l {
            color: var(--filter--button--hover--font-color);
          }
        }

        &:focus-visible {
          outline: var(--filter-outline);
          outline-offset: 1px;
        }
      }

      .optionsContainer {
        background-color: var(--white);
        width: 100%;
        max-width: 100%;

        @include h.breakpoint-l {
          position: absolute;
          z-index: 5;
          width: var(--filter--name--width);
          padding: 5px 0 10px;
          max-width: 300px;
          box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.25);
          top: 44px;
          border: 1px var(--blue-60) solid;
          border-radius: var(--filter--button--hover--bottom-border-radius);
        }

        @include h.breakpoint-x {
          max-width: var(--filter--button--max-width);
        }

        .option {
          height: 40px;
          display: flex;
          align-items: center;
          width: 100%;

          @include h.breakpoint-m {
            height: auto;
            min-height: 40px;
          }

          &.disabled {
            pointer-events: none;

            & .label {
              opacity: 0.5;

              &::before {
                background: var(--grey-40);
              }
            }
          }

          label {
            display: flex;
            width: 100%;
            align-items: center;
            color: var(--filter--label--color);
            cursor: pointer;
            font-family: var(--filter--label--font-family);
            font-size: var(--filter--label--font-size);
            font-weight: var(--filter--label--font-weight);
            height: 100%;
            line-height: var(--filter--label--line-height);
            margin: 4px 0;
            min-height: 50px;
            padding: 0;
            user-select: none;

            @include h.breakpoint-l {
              min-height: unset;
              padding-left: 20px;
            }

            .multiple & {
              font-size: var(--filter--label--multiple--font-size);
              font-weight: var(--filter--label--multiple--font-weight);
              justify-content: flex-start;
              line-height: var(--filter--label--multiple--line-height);
              text-transform: var(--filter--label--multiple--text-transform);
              width: 100%;

              &::before {
                background: var(--white);
                border: 1px solid var(--grey-50);
                box-sizing: border-box;
                content: '';
                display: none;
                height: 20px;
                margin-right: 12px;
                vertical-align: baseline;
                width: 20px;

                @include h.breakpoint-l {
                  display: block;
                }
              }

              @include h.breakpoint-l {
                align-items: center;
                padding: 14px 20px 11px;
                text-align: left;

                &:hover {
                  background-color: transparent;
                  color: initial;
                  opacity: 1;

                  &::before {
                    background-color:
                      var(
                        --filter--label--hover--background-color
                      );
                    border-width: 0;
                    opacity: 40%;
                    border: var(--filter--label--hover--border);
                  }
                }
              }
            }

            .visualIndicator {
              box-sizing: border-box;
              display: inline-flex;
              height: 20px;
              min-width: 20px;
              margin-right: 16px;
              justify-content: center;
              align-items: center;
              padding: 0;
              border: solid 1px #999;

              &.radio {
                border-radius: 50%;
              }
            }

            input {
              position: absolute;
              opacity: 0;
              height: 0;
              width: 0;
            }
          }

          &:has(input:focus-visible) {
            .visualIndicator {
              outline: solid 3px #57ad57;
              outline-offset: 1px;
            }
          }

          &:focus-visible:not(:active) {
            .visualIndicator {
              outline: solid 3px #57ad57;
              outline-offset: 1px;
            }
          }

          label:hover .visualIndicator::before {
            content: '';
            height: 75%;
            width: 75%;
            background: var(--filter--label--hover--input);
          }

          label:hover .visualIndicator.radio::before {
            border-radius: 50%;
          }

          label:has(input:checked) .visualIndicator::before {
            background: var(--filter--option--selected--background-color);
            content: '';
            height: 75%;
            width: 75%;
          }

          label:has(input:checked) .visualIndicator.radio::before {
            border-radius: 50%;
          }
        }
      }
    }
  }
}
